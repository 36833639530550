import $api from '../http';
import { AxiosResponse } from 'axios';
import { URLS } from '@/constants';

export default class GameSetvice {
    static async fetchGamesByDate(date: string): Promise<AxiosResponse<{ games: any[]} >> {
        return $api.get(URLS.api.games(date));
    }

    static async findGameByCode(code: string): Promise<AxiosResponse<{ game: boolean, id: string }>> {
        return $api.get(URLS.api.gameByCode(code));
    }
}


import { Vue } from 'vue-class-component';
import GameSetvice from '@/services/GameService';

export default class GamesPage extends Vue {
    games: any[] = [];
    counterMode = {
        mode16: 0,
        mode36: 0,
        mode64: 0,
    };

    async mounted() {
        const date = String(this.$route.query.date);
        const { data } =  await GameSetvice.fetchGamesByDate(date);
        if (data.games.length > 0) {
            this.games = data.games;
            this.counterMode.mode16 = data.games.filter(game => game.mode === 16).length;
            this.counterMode.mode36 = data.games.filter(game => game.mode === 36).length;
            this.counterMode.mode64 = data.games.filter(game => game.mode === 64).length;
        }
    }
}

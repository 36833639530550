<template>
    <div class="mode-counters">
        <p>Режим 16: {{ counterMode.mode16 }}</p>
        <p>Режим 36: {{ counterMode.mode36 }}</p>
        <p>Режим 64: {{ counterMode.mode64 }}</p>
        <p>Всего: {{ games.length }}</p>
    </div>
    <table class="games-table">
        <tr>
            <th>N</th>
            <th>ID</th>
            <th>Режим</th>
            <th>Игрок</th>
            <th>Победитель</th>
            <th>Дата</th>
        </tr>
        <tr v-for="(game, i) in games" :key="game._id">
            <td class="number">{{ i + 1 }}</td>
            <td>{{ game._id }}</td>
            <td>{{ game.mode }}</td>
            <td class="player">
                <p>Почта: {{ game.player.email }}</p>
                <p>Побед: {{ game.player?.countWinner }}</p>
            </td>
            <td class="winner">
                <p>Почта: {{ game.winner?.email }}</p>
                <p>Побед: {{ game.winner?.countWinner }}</p>
            </td>
            <td>{{ game.date }}</td>
        </tr>
    </table>
</template>
<script lang="ts">
import { Vue } from 'vue-class-component';
import GameSetvice from '@/services/GameService';

export default class GamesPage extends Vue {
    games: any[] = [];
    counterMode = {
        mode16: 0,
        mode36: 0,
        mode64: 0,
    };

    async mounted() {
        const date = String(this.$route.query.date);
        const { data } =  await GameSetvice.fetchGamesByDate(date);
        if (data.games.length > 0) {
            this.games = data.games;
            this.counterMode.mode16 = data.games.filter(game => game.mode === 16).length;
            this.counterMode.mode36 = data.games.filter(game => game.mode === 36).length;
            this.counterMode.mode64 = data.games.filter(game => game.mode === 64).length;
        }
    }
}
</script>

<style scoped lang="sass">
.mode-counters
    width: 100%
    margin: 20px 0
    height: 60px
    padding: 0 15px
.games-table
    width: 100%
    height: calc(100vh - 110px)
    display: block
    overflow: auto
    position: relative
    border-collapse: collapse
    border-spacing: 0
    padding: 0 15px

    tr
        height: 40px
    .player,
    .winner
        width: 300px
    .number
        width: 100px

</style>